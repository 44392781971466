
import { rzContainer, rzDottedLoader } from '@razlet/ui';

export default {
  components: {
    rzContainer, 
    rzDottedLoader,
  },
  data() {
    return {
      isAffiliatorLoaded:false,
      isAffiliatorReady:false,
      isAffiliatorHome:false,
      showAffiliator: true,
      route: '',
    };
  },
  computed: {
    isIndexPage() {
      return this.$route.name === 'index';
    },
    isFlightsPage() {
      return this.$route.name === 'flights';
    },
    showRouterView() {
      return this.isAffiliatorHome || (!this.isIndexPage && this.isMobile);
    },
  },
  watch: {
    '$route.name': {
      handler(value) {
        if (value !== 'flights') {
          this.route = '/';
        }
        if (this.isMobile) {
          const afiliatorShowRoutes = [
            'index', 
            'flights', 
            'for-passengers-naiti-oplatit-zakaz', 
            'for-passengers-proverit-skachat-bilet', 
            'for-passengers-zayavka_na_vozvratobmen_bileta',
          ];
          this.showAffiliator = afiliatorShowRoutes.includes(value);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.customElements.whenDefined('razlet-affiliator').then(() => {
      this.isAffiliatorLoaded = true;
    });
    window.addEventListener('home-mounted', () => {
      this.isAffiliatorHome = true;
    });
    window.addEventListener('home-destroyed', () => {
      this.isAffiliatorHome = false;
    });
    window.addEventListener('back-home', () => {
      if (this.$route.name === 'flights') this.$router.push('/');
    });
    window.addEventListener('success-search', (data) => {
      const route = `variants/${data.detail.uid}?search=${data.detail.search}`;
      this.$router.push({ name: 'flights', hash: `#/${route}` });
      this.route = route;
    });
  },
  destroyed() {
    window.removeEventListener('home-mounted', () => {});
    window.removeEventListener('home-destroyed', () => {});
    window.removeEventListener('success-search', () => {});
  },
  methods: {
    ready() {
      this.isAffiliatorReady = true;
    },
  },
};
